import Vue from 'vue'
import VueCurrencyFilter from 'vue-currency-filter'

const config = {
  symbol : '€',
  thousandsSeparator: '.',
  fractionCount: 2,
  fractionSeparator: ',',
  symbolPosition: 'front',
  symbolSpacing: true,
  avoidEmptyDecimals: undefined,
}

Vue.use(VueCurrencyFilter, config)

Vue.filter( 'tenzerCurrency', function( value ){
  let price = parseFloat( value).toFixed( 2 );
  return Vue.options.filters.currency( price  );
})