import Vue from 'vue'

// Lib imports
import axios from 'axios'
import router from '@/router'
import Store from '@/store'

const config = axios

config.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
config.defaults.headers.common['Accept'] = 'application/json'

const API_URL = 'https://app.tenzer.nl/api/'
config.defaults.baseURL = API_URL


axios.interceptors.request.use((request) => {
  // Do something before request is sent
  request.headers['app-lang'] = Store.getters['getLang']

  return request;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Interceptors to Handle invalid token of user
config.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response.status === 406) {
      router.push({ path: '/404' })
    }

    if (error.response.status === 422) {
      Vue.$toast.error(error.response.data.message)
    }

    if (error.response.status === 404) {
      Vue.$toast.error('Something went wrong! Please try again later.')
    }

    if (error.response.status === 500) {
      Vue.$toast.error('Something went wrong! Please try again later.')
    }

    return Promise.reject(error)
  }
)

Vue.prototype.$http = config
