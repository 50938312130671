export default [
  {
    path: '/product-list',
    name: 'ProductList',
    view: 'ProductList',
  },
  {
    path: '/checkout',
    name: 'Checkout',
    view: 'Checkout'
  },
  {
    path: '/cart',
    name: 'Cart',
    view: 'Cart'
  },
  {
    path: '/order-success',
    name: 'OrderSuccess',
    view: 'OrderSuccess'
  },
  {
    path: '/order-failed',
    name: 'OrderFailed',
    view: 'OrderFailed'
  },
  {
    path: '/events',
    name: 'Events',
    view: 'Events'
  },
  {
    path: '/events/register/:id',
    name: 'EventRegister',
    view: 'EventRegister'
  },
  {
    path: '/products/:type',
    name: 'Products',
    view: 'Products',
    meta: {
      layout: 'product-layout'
    }
  },
  {
    path: '/subscription-success',
    name: 'SubscriptionSuccess',
    view: 'SubscriptionSuccess'
  },
  {
    path: '/opening-hours',
    name: 'OpeningHours',
    view: 'OpeningHours'
  },
  {
    path: '/facilities',
    name: 'OverviewDetailList',
    view: 'OverviewDetailList',
    meta: {
      id: 1
    }
  },
  {
    path: '/surroundings',
    name: 'OverviewDetailList',
    view: 'OverviewDetailList',
    meta: {
      id: 2
    }
  },
  {
    path: '/surroundings-child/:id',
    name: 'OverviewDetailList',
    view: 'OverviewDetailList',
  },
  {
    path: '/overview/:id',
    name: 'OverviewDetailPage',
    view: 'OverviewDetailPage',    
  },
  {
    path: '/redirect',
    name: 'Redirect',
    view: 'Redirect',
  }
]
