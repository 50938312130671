export default {
  cartBucket: [],
  appSetting: '1',
  showHeader: 1,
  settingsData: {},
  sandwichConfiguration: {
    bannerUrl: null,
    title: null,
    description: null,
    sandwichEndTime: null,
    msgAfterDeadLine: null,
  },
  sandwichSuccessData : {},
  snackConfiguration: {
    bannerUrl: null,
    title: null,
    description: null
  },
  eventConfiguration: {
    bannerUrl: null,
    title: null,
    description: null
  },
  appLang: new URL(location.href).searchParams.get('lang') || 'nl',
  events:[],
  eventsBackBtn: false,
  overview_path: null,
  backButton: false,
  pickupDates: [],
  pickupTimes: [],
  selectedPickupDate: null,
  selectedPickupTime: null
}
