import arraysEqual from "../utilities/arrays-equal"

export default {
  ADD_TO_CART (state, payload) {
    payload = JSON.parse(JSON.stringify(payload))

    if (state.cartBucket.length){
      if (payload.type != state.cartBucket[0].type){
        state.cartBucket = []
      }
    }

    const existingProduct = state.cartBucket.find(
      (product) => {
        if (payload.type === 'snack') {
          const alreadySelectedAdjustments = product.adjustment.filter(adjustment => adjustment.selected).map(adjustment => adjustment.id).sort()
          const newlySelectedAdjustments = payload.adjustment.filter(adjustment => adjustment.selected).map(adjustment => adjustment.id).sort()
          const snackSame = arraysEqual(alreadySelectedAdjustments, newlySelectedAdjustments)

          return product.id === payload.id && snackSame
        }

        return product.id === payload.id
      }
    )

    if (state.cartBucket.length && existingProduct) {
      const alreadySelectedAdjustments = existingProduct.adjustment.filter(adjustment => adjustment.selected).map(adjustment => adjustment.id).sort()
      const newlySelectedAdjustments = payload.adjustment.filter(adjustment => adjustment.selected).map(adjustment => adjustment.id).sort()

      if (arraysEqual(alreadySelectedAdjustments, newlySelectedAdjustments)) {
        const recordIndex = state.cartBucket.indexOf(existingProduct)
        Object.assign(state.cartBucket[recordIndex], payload)
        return  
      } else {
        payload.quantity = 1;
      }
    }

    state.cartBucket.push(payload)
  },

  MAKE_CART_EMPTY (state) {
    state.cartBucket = []
  },

  REMOVE_FROM_CART (state, payload) {
    const existingProduct = state.cartBucket.find(
      (product) => {
        let passedcheck = true

        if (payload.type === 'snack') {
          const alreadySelectedAdjustments = product.adjustment.filter(adjustment => adjustment.selected).map(adjustment => adjustment.id).sort()
          const newlySelectedAdjustments = payload.adjustment.filter(adjustment => adjustment.selected).map(adjustment => adjustment.id).sort()
          passedcheck = arraysEqual(alreadySelectedAdjustments, newlySelectedAdjustments)
        }

        return product.id === payload.id && passedcheck
      }
    )

    if (state.cartBucket.length && existingProduct) {
      const recordIndex = state.cartBucket.indexOf(existingProduct)
      state.cartBucket.splice(recordIndex, 1)
    }
  },

  SET_SETTING_ID (state, payload) {
    state.appSetting = payload
  },

  SHOW_HEADER (state, payload) {
    state.showHeader = payload;
  },

  BACK_BUTTON (state, payload) {
    state.backButton = payload;
  },

  SET_LANGAGE (state, payload) {
    state.appLang = payload
  },

  SET_SANDWICH_CONFIGURATION (state, payload) {
    state.sandwichConfiguration = { ...payload }
  },

  SET_SANDWICH_SUCCESSDATA (state, payload) {
    state.sandwichSuccessData = payload
  },

  SET_SNACk_CONFIGURATION (state, payload) {
    state.snackConfiguration = { ...payload }
  },

  SET_EVENT_CONFIGURATION (state, payload) {
    state.eventConfiguration = { ...payload }
  },

  SET_EVENTS (state, payload) {
    state.events = payload
  },

  SET_SETTINGS_DATA ( state, payload) {
    state.settingsData = payload
  },

  SET_EVENTS_BACK_BUTTON_FLAG ( state, payload) {
    state.eventsBackBtn = (payload == 'true');
  },
  SET_OVERVIEW_PATH ( state, payload) {
    state.overview_path = payload;
  },
  SET_PICKUP_DATES(state, payload) {
    state.pickupDates = payload;
  },
  SET_PICKUP_TIMES(state, payload) {
    let last_ele = payload.length - 1
    let final_timeslot = []
    for (let ts in payload) {
      if (last_ele == ts) {
        final_timeslot.push(payload[ts])
        final_timeslot.push({
          start_time: payload[ts].end_time,
          end_time: payload[ts].end_time
        })
      } else {
        final_timeslot.push(payload[ts])
      }
    }
    state.pickupTimes = final_timeslot;
  },
  SET_SELECTED_PICKUP_DATE(state, payload) {
    state.selectedPickupDate = payload;
  },
  SET_SELECTED_PICKUP_TIME(state, payload) {
    state.selectedPickupTime = payload;
  }
}
