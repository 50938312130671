import parsePrice from '../utilities/parse-price'

export default {
  getCartItems (state) {
    return state.cartBucket
  },

  getCartItemCount (state) {
    return state.cartBucket.reduce((total, item) => {
      return total + item.quantity
    }, 0)
  },

  getSettingId (state) {
    return state.appSetting
  },

  getShowHeader (state) {
    return state.showHeader
  },

  getBackButton (state) {
    return state.backButton
  },

  getLang (state) {
    return state.appLang
  },

  getSandwichConfiguration (state) {
    return state.sandwichConfiguration
  },

  getSandwichSuccessData (state) {
    return state.sandwichSuccessData
  },

  getSnackConfiguration (state) {
    return state.snackConfiguration
  },

  getEventConfiguration (state) {
    return state.eventConfiguration
  },

  getEventById: (state) => (id) => {
    return state.events.find(evnt => evnt.id === id)
  },

  getCartTotal (state) {
    return state.cartBucket.reduce((total, item) => {
      const basePrice = parsePrice(item.price) + item.adjustment
      .filter(adjustment => adjustment.selected)
      .reduce((subtotal, adjustment) => {
        return subtotal + adjustment.price
      }, 0)

      return total + (basePrice * (item.quantity || 1))
    }, 0)
  },

  getSettingData (state) {
    return state.settingsData;
  },

  cartHasProductType: (state) => (type) => {
    return !!state.cartBucket.find(product => product.type === type)
  },

  getEventBackBtnFlag (state) {
    return state.eventsBackBtn
  },
  getLastOverviewPath (state) {
    return state.overview_path
  },
  getPickupDates(state) {
    return state.pickupDates
  },
  getPickupTimes(state) {
    return state.pickupTimes
  },
  getSelectedPickupDate(state) {
    let temp_check = false
    for (let tmp_date in state.pickupDates){
      if (state.pickupDates[tmp_date].date == state.selectedPickupDate){
        temp_check = true 
      }
    }
    if (temp_check) {
      return state.selectedPickupDate
    } else {
      return null
    }
  },
  getSelectedPickupTime(state) {
    let temp_check = false
    for (let tmp_time in state.pickupTimes) {
      if (state.pickupTimes[tmp_time].start_time == state.selectedPickupTime) {
        temp_check = true
      }
    }
    if (temp_check){
      return state.selectedPickupTime
    }else{
      return null
    }
  }
}
