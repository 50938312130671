<template>
  <div class="main-conatiner">
    <loader ref="pageLoader" />
    <product-header />

    <router-view />

    <ScrollTopButton />
  </div>
</template>

<script>
  import Loader from '@/components/core/Loader'
  import ProductHeader from './ProductHeader.vue'
  import ScrollTopButton from './ScrollTopButton.vue'

  export default {
    name: 'ProductLayout',

    components: {
      ProductHeader,
      Loader,
      ScrollTopButton
    },

    mounted () {
      this.$root.$load = this.$refs.pageLoader.toggleLoader
    }
  }
</script>
