import Vue from 'vue'
import router from '@/router'
import store from '@/store'
import i18n from '@/i18n'
import App from './App.vue'

// Sync router with store
import { sync, unsync } from 'vuex-router-sync'

import './plugins'

Vue.config.productionTip = false

// Sync store with router
sync(store, router)

/* eslint-disable no-new */
new Vue({
  i18n,
  router,
  store,
  destroyed () {
    unsync()
  },
  render: h => h(App)
}).$mount('#app')
