<template>
  <div class="main-conatiner">
    <loader ref="pageLoader" />
    <header-view />

    <router-view />

    <ScrollTopButton />
  </div>
</template>

<script>
  import Loader from '@/components/core/Loader'
  import ScrollTopButton from '../core/ScrollTopButton'
  const HeaderView = () => import('@/components/core/Header')

  export default {
    name: 'Layout',

    components: {
      HeaderView,
      Loader,
      ScrollTopButton
    },

    mounted () {
      this.$root.$load = this.$refs.pageLoader.toggleLoader
    }
  }
</script>
