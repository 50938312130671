<template>
  <div
    v-if="showLoader"
    class="loader"
  >
    <div class="loader-container">
      <loading-ripple />
    </div>
  </div>
</template>

<script>
  import LoadingRipple from '@/components/core/LoadingRipple'

  export default {
    name: 'Loader',

    components: {
      LoadingRipple
    },

    data () {
      return {
        showLoader: false
      }
    },

    methods: {
      toggleLoader (v) {
        this.showLoader = v
      }
    }
  }
</script>
