import Vue from 'vue'
import VeeValidate from 'vee-validate'
import en from './validation-messages/en'
import nl from './validation-messages/nl'
import de from './validation-messages/de'
import i18n from '@/i18n'

Vue.use(VeeValidate, {
   i18nRootKey: 'validations', // customize the root path for validation messages.
   i18n,
   dictionary: {
    en,
    nl,
    de
   }
});

// Register custom rule for discounts number format
const mobile = 'mobile'
VeeValidate.Validator.extend(mobile, {
  getMessage: field => `Enter a valid ${field}.`,
  validate: value => {
    var percentageRegex = new RegExp("^(\\+\\d{1,3}[- ]?)?\\d{10}$")
    return percentageRegex.test(value)
  }
})
