export default {
  addItemToCart ({ commit }, payload) {
    commit('ADD_TO_CART', payload)
  },

  removeItemFromCart ({ commit }, payload) {
    commit('REMOVE_FROM_CART', payload)
  },

  makeCartEmpty ({ commit }) {
    commit('MAKE_CART_EMPTY')
  },
  
  setAppSetting ({ commit }, settingId) {
    commit('SET_SETTING_ID', settingId)
  },

  showHeader ({ commit }, showHeader) {
    commit('SHOW_HEADER', showHeader)
  },

  backButton ({ commit }, backButton) {
    commit('BACK_BUTTON', backButton)
  },
  
  setAppLanguage({ commit }, lang) {
    commit('SET_LANGAGE', lang)
  },

  removeAllSandwichItemsFromCart ({ commit, state }) {
    state.cartBucket.forEach(product => {
      if ( product.type == 'sandwich' ) {
        commit('MAKE_CART_EMPTY', product)
      }
    });
  },

  removeAllSnackItemsFromCart ({ commit, state }) {
    state.cartBucket.forEach(product => {
      if ( product.type === 'snack' ) {
        commit('MAKE_CART_EMPTY', product)
      }
    });
  }
}
