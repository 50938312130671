import en from 'vee-validate/dist/locale/en'

export default {
    ...en,

    custom: {
        "Email": {
          email: 'Email is mandatory'
        },
        terms: {
          required: 'The terms field is required.'
        },
      }
  };