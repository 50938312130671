<template>
  <component :is="layout" /> 
</template>

<script>
  import Layout from '@/components/core/Layout'
  import ProductLayout from '@/components/core/ProductLayout'
  import { mapGetters } from 'vuex'

  export default {
    name: 'App',

    components: {
      Layout,
      ProductLayout
    },

    data() {
      return {
        default_layout: 'layout',
      }
    },

    mounted() {
      this.$http.post('get-setting', { setting_id: this.settingId })
        .then((response) => {
          this.$store.commit('SET_SETTINGS_DATA', response.data.data);
        })
        .catch((e) => { console.log(e) })
    },

    computed:{
       ...mapGetters({ settingId: 'getSettingId' }),

      layout() {
        if( this.$route && this.$route.meta && this.$route.meta.layout ){
          return this.$route.meta.layout;
        }

        return this.default_layout;
      }
    }
  }
</script>
