<template>
  <div >
    <transition
      name="cart-bounce-in"
    >
      <router-link
              class="top-cart-btn"
              tag="a"
              to="/cart"
              v-if="cartItemCount > 0"
            >
        <div class="top-cart-container">
            <div class="shopping-cart-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path fill="rgb(255,255,255)" fill-rule="evenodd" d="M19.38199997 6c.69799995 0 1.33399963.3540001 1.70100021.94799995.36700058.59399987.39999962 1.3210001.08799935 1.9460001l-3.27599907 6.5529995C17.72500038 15.78600025 17.3789997 16 17 16H8c-.45100021 0-.8460002-.30200005-.96500015-.73700047L4.23600006 5H3c-.55299997 0-1-.44799995-1-1s.44700003-1 1-1h2c.45100021 0 .84599972.30200005.96500015.73699999L6.58199978 6h12.80000019zm-3 8H8.76399994L7.12699986 8h12.2550001l-3 6zM7.5 21c.82800007 0 1.5-.67099953 1.5-1.5S8.32800007 18 7.5 18 6 18.67099953 6 19.5 6.67199993 21 7.5 21zm10 0c.82799911 0 1.5-.67099953 1.5-1.5s-.67200089-1.5-1.5-1.5-1.5.67099953-1.5 1.5.67200089 1.5 1.5 1.5z"/>
              </svg>
            </div>
            <span class="cart-count">{{cartItemCount}}</span>
        </div>
      </router-link>
    </transition>
    <div
      class="header-main-img"
      v-if="showHeader == 1"
    >
      <img :src="headerImage" alt="header-img" class="img-fluid header-img">
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
  export default {
    name: 'ProductHeader',

    data () {
      return {
        images: {
          common: 'heaer_bg.jpg',
          event: 'event-banner.png'
        },
        selectedImage: 'common'
      }
    },
    computed: {
      ...mapGetters({ cartItemCount: 'getCartItemCount', sandwichConfiguration: 'getSandwichConfiguration', snackConfiguration: 'getSnackConfiguration', showHeader: 'getShowHeader' }),

      headerImage() {
        if (this.$route.path.includes('sandwich')) {
          return this.sandwichConfiguration.bannerUrl || `/banners/${this.images[this.selectedImage]}`
        } else if (this.$route.path.includes('snack')) {
          return this.snackConfiguration.bannerUrl || `/banners/${this.images[this.selectedImage]}`
        } else {
          return `/banners/${this.images[this.selectedImage]}`
        }
      }
    },
    watch: {
      '$route.name': {
        handler (val) {
          if (['Events', 'EventRegister'].includes(val)) {
            this.selectedImage = 'event'
          }
        },
        immediate: true,
        deep: true
      }
    }
  }
</script>

<style scoped>
.cart-bounce-in-enter-active, .cart-bounce-in-leave-active {
  transform: translateX(0) translateY(0);
}
.cart-bounce-in-enter, .cart-bounce-in-leave-to {
  transform: translateX(100px) translateY(-100px);
}
</style>
