/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import Store from '@/store'
import i18n from '@/i18n'

// Routes
import paths from './paths'

function route (path, view, name, children, meta) {
  children = children || []
  return {
    name: name || view,
    path,
    component: (resovle) => import(`@/pages/${view}.vue`/* webpackChunkName: 'pages/' */).then(resovle),
    children: children.map(child => route(child.path, child.view, child.name, child.children, child.meta)),
    meta
  }
}

Vue.use(Router)

// Create a new router
const router = new Router({
  mode: 'history',
  routes: paths.map(path => route(path.path, path.view, path.name, path.children, path.meta)).concat([
    { path: '*', redirect: '/' }
  ]),
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  }
})

Vue.use(Meta)

router.beforeEach((to, from, next) => {
  const params = to.query

  if (params && params.settings_id) {
    Store.dispatch('setAppSetting', params.settings_id)
  }

  if ( params && params.show_header) {
    Store.dispatch('showHeader', params.show_header)
  }

  if ( params && params.back_button) {
    Store.dispatch('backButton', params.back_button)
  }

  if (params && params.lang) {
    Store.dispatch('setAppLanguage', params.lang)
    const locale = params.lang
    const supported_locales = ['en', 'nl','de']
    if (!supported_locales.includes(locale)) return next('en')
    if (i18n.locale !== locale) {
      i18n.locale = locale
    }
  }
  return next()
})

export default router
