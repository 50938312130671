<template>
  <a v-show="progress > 0" @click="scrollToTop" class="scroll-to-top-container">
    <RadialProgressBar
      :completed-steps="progress"
      :total-steps="100"
      :diameter="56"
      :strokeWidth="9"
      :innerStrokeWidth="9"
      strokeLinecap="butt"
      startColor="#676767"
      stopColor="#676767"
      innerStrokeColor="#e8e8e8"
      :animateSpeed="100"
    >
    <div class="scroll-button-contents">
      <i class="fas fa-arrow-up"></i>
    </div>
    </RadialProgressBar>
  </a>
</template>

<script>
import RadialProgressBar from "vue-radial-progress";

export default {
  name: "ScrollTopButton",

  components: {
    RadialProgressBar,
  },

  data() {
    return {
      progress: 0
    }
  },

  mounted() {
    document.addEventListener('scroll', this.calculateProgress)
  },

  beforeDestroy() {
    document.removeEventListener('scroll', this.calculateProgress)
  },

  methods: {
    scrollToTop() {
      document.documentElement.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },

    calculateProgress() {
      const threshold = window.innerHeight * 0.3
      const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
      const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      var progress = Math.round((winScroll / height) * 100);

      if (winScroll >= threshold) {
        this.progress = progress;
      } else {
        this.progress = 0;
      }
    }
  },
};
</script>
